<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> QSO Report (Client)
        </h2>
        <div style="background-color: white; padding:2%;">
            <div class="mt-4">
                <div class="mb-4">
                    <div class="row">
                        <div class="col-lg-2 col-md-3">
                            <h6>Start Date-Time</h6>
                            <div class="wrap">
                                <ejs-datetimepicker :strictMode="true" :placehoder="'Select Start Date and Time'" v-model="filters.start_date" ></ejs-datetimepicker>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Stop Date-Time</h6>
                            <div class="wrap">
                                <ejs-datetimepicker :strictMode="true" :min="filters.start_date" :placehoder="'Select Stop Date and Time'" v-model="filters.stop_date" ></ejs-datetimepicker>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Domain</h6>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper">
                            <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Domain" v-model="filters.domain" />
                        </span>
                        </div>

                        <div class="col-lg-2 col-md-3">
                            <div class="">
                                <h6>QSO</h6>
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' v-model="filters.qso" :dataSource='answers' :mode='true' placeholder='QSO' ></ejs-dropdownlist>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 34px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" @click="search" style="color: white; background-color: rgb(56, 56, 56);" >
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-grid ref="grid" :columns="columns" :dataSource="data" :allowPaging="true" :allowResizing="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
                <e-columns>
                    <e-column field="output_uid" headerText="UID" text-Align="Left" ></e-column>
                    <e-column field="input_date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                    <e-column headerText='Input Details' :columns='inputCols'></e-column>
                    <e-column headerText='Output Details' :columns='outputCols'></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { GridPlugin, PdfExport, ExcelExport, Toolbar} from '@syncfusion/ej2-vue-grids';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DateTimePickerPlugin);

export default {
    name: "qso-client",
    data() {
        return {
            filters: {
                start_date: new Date(new Date().setHours(0, 0, 0, 0)),
                stop_date: "",
                domain: "",
                qso: "",
            },
            outputCols: [
                { field: 'output_traffic_link', headerText: 'Traffic Link', textAlign: 'Left' },
                { field: 'output_ip_address', headerText: 'IP', textAlign: 'Left' },
                { field: 'output_geo_location', headerText: 'Location', textAlign: 'Left' },
                { field: 'output_qso', headerText: 'QSO', textAlign: 'Left' },
                { field: 'output_url', headerText: 'URL', textAlign: 'Left' },
                { field: 'output_response', headerText: 'Response', textAlign: 'Left' },
            ],
            inputCols: [
                { field: 'input_geo_location', headerText: 'Location', textAlign: 'Left' },
                { field: 'input_clicks_needed', headerText: 'Clicks Needed', textAlign: 'Left' },
                { field: 'input_input_type', headerText: 'Type', textAlign: 'Left' },
            ],
            dateVal : new Date(new Date().setHours(0, 0, 0, 0)),
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            data: [
                {
                _id : {
                    "qso_tracker" : "cl5hbbw41001dhn02g84f1w1i",
                    "hour" : 18,
                    "minute" : 29,
                    "second" : 10,
                    "city" : "Austin"
                },
                input_uid : "cl5hbbw41001dhn02g84f1w1i",
                input_date : "2022-07-11 18:29",
                input_geo_location : "Austin",
                input_clicks_needed : 91,
                input_input_type : "api",
                output_uid : "cl5hbbw41001dhn02g84f1w1i",
                output_date : "2022-07-11 18:29",
                output_traffic_link : 5503,
                output_ip_address : "24.55.56.172",
                output_geo_location : "Austin",
                output_qso : "yes",
                output_url : "https://www.landonearth.com/d/",
                output_response : "Not Capped"
            },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbaqpx0000hn023g477zru",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 15,
                        "city" : "Denver"
                    },
                    "input_uid" : "cl5hbaqpx0000hn023g477zru",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Denver",
                    "input_clicks_needed" : 1417,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbaqpx0000hn023g477zru",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "71.33.193.72",
                    "output_geo_location" : "Denver",
                    "output_qso" : "yes",
                    "output_url" : "https://www.castlerockford.com/reviews.htm",
                    "output_response" : "Not Capped"
                },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbc48g001rhn02fpk926mg",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 18,
                        "city" : "Kansas City"
                    },
                    "input_uid" : "cl5hbc48g001rhn02fpk926mg",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Kansas City",
                    "input_clicks_needed" : 29,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbc48g001rhn02fpk926mg",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "24.166.182.22",
                    "output_geo_location" : "Kansas City",
                    "output_qso" : "yes",
                    "output_url" : "https://m.youtube.com/c/SilverCymbal/playlists",
                    "output_response" : "Not Capped"
                },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbbo0o000zhn02d1cx6utv",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 20,
                        "city" : "Emeryville"
                    },
                    "input_uid" : "cl5hbbo0o000zhn02d1cx6utv",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Emeryville",
                    "input_clicks_needed" : 268,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbbo0o000zhn02d1cx6utv",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "73.70.187.234",
                    "output_geo_location" : "Emeryville",
                    "output_qso" : "yes",
                    "output_url" : "http://www.newsandpromotions.com/mobile-apps/",
                    "output_response" : "Capped"
                },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbbof40011hn02chwpgfpq",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 21,
                        "city" : "Oakland"
                    },
                    "input_uid" : "cl5hbbof40011hn02chwpgfpq",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Oakland",
                    "input_clicks_needed" : 268,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbbof40011hn02chwpgfpq",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "24.23.163.173",
                    "output_geo_location" : "Oakland",
                    "output_qso" : "yes",
                    "output_url" : "http://www.newsandpromotions.com/home-builder/",
                    "output_response" : "Capped"
                },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbbof40011hn02chwpgfpq",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 24,
                        "city" : "Oakland"
                    },
                    "input_uid" : "cl5hbbof40011hn02chwpgfpq",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Oakland",
                    "input_clicks_needed" : 268,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbbof40011hn02chwpgfpq",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "24.23.163.173",
                    "output_geo_location" : "Oakland",
                    "output_qso" : "yes",
                    "output_url" : "http://www.newsandpromotions.com/author/jrussell/",
                    "output_response" : "Capped"
                },
                {
                    "_id" : {
                        "qso_tracker" : "cl5hbblo3000xhn02b5clg9ef",
                        "hour" : 18,
                        "minute" : 29,
                        "second" : 26,
                        "city" : "Glendale"
                    },
                    "input_uid" : "cl5hbblo3000xhn02b5clg9ef",
                    "input_date" : "2022-07-11 18:29",
                    "input_geo_location" : "Glendale",
                    "input_clicks_needed" : 298,
                    "input_input_type" : "api",
                    "output_uid" : "cl5hbblo3000xhn02b5clg9ef",
                    "output_date" : "2022-07-11 18:29",
                    "output_traffic_link" : "5503",
                    "output_ip_address" : "24.56.29.86",
                    "output_geo_location" : "Glendale",
                    "output_qso" : "yes",
                    "output_url" : "http://www.newsandpromotions.com/weather/",
                    "output_response" : "Capped"
                }
            ],
            columns: {},
            answers: [{text: 'No', value: 'no'}, {text: 'Yes', value: 'yes'}],
            city_fields: { text: 'city_name' },
        }
    },
    provide: {
      grid: [ PdfExport, ExcelExport, Toolbar ],
    },
    created() {
        this.$root.preloader = false;
    },
    methods: {
        toolbarClick(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties() {
            return {
                fileName: "qso_export.pdf",
                pageOrientation: 'Landscape',
                pageSize: 'Legal'
            };
        },
        search() {
            axios.post(`${this.$root.serverUrl}/admin/reports/qso-client`, { filters: this.filters }).then((resp) => {
                console.log(resp.data);
            })
        }
    }
}
</script>

<style scoped>

</style>
